import React from "react"

const notFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center py-main">
      <div className="py-6">
        <h1 className="text-center mt-6">Page Not Found</h1>
      </div>
    </div>
  )
}

export default notFound



// import React from 'react'

// import SEO from "../components/seo"
// import { ApolloProvider, gql, useQuery } from '@apollo/client'
// import { client } from '../apollo/client'
// import { Router, useParams } from "@reach/router"

// import HomePageComponents from '../components/pages/home'
// import { navigate } from "gatsby"

// import FailedPage from '../components/pages/no-valid'
// import SuccessPage from "../templates/success"

// const TselPage = () => {

//   const params = useParams();
//   const { loading, data, error } = useQuery(getVocher, {
//     variables: { redeemId: params?.code },
//   })

//   const [showPage, setShowPage] = React.useState(true)
//   const [code, setCode] = React.useState("")

//   React.useEffect(() => {
//     if (data) {
//       let monstCode = params.code;
//       if (data?.redeemCode) {
//         if (monstCode) {
//           setShowPage(true)
//           setCode(monstCode)
//         } else {
//           navigate(`/tsel/failed/no-valid`)
//         }
//       } else {
//         navigate(`/tsel/failed/${monstCode}`)
//       }
//     }
//   }, [data])

//   // let monstCode = params?.code
//   // if (monstCode) {
//   //   if (data?.redeemCode) {
//   //     let { redeemAt, title } = data?.redeemCode;
//   //     if (title === monstCode) {
//   //       if (redeemAt?.redeemDate) {
//   //         navigate(`/tsel/failed/${monstCode}`)
//   //       }
//   //     } else {
//   //       navigate(`/tsel/failed/${monstCode}`)
//   //     }
//   //   }
//   // }

//   return (
//     <>
//       { showPage && (
//         <>
//           <SEO title="Tsel Redeem" />
//           <HomePageComponents code={code} />
//         </>
//       )}
//     </>
//   )
// }


// const PageRouter = () => {
//   return (
//     <Router basepath="/tsel">
//       {/* <TselPage path="/" /> */}
//       <TselPage path="/:code" />
//       <FailedPage path="/failed/:code" />
//       <SuccessPage path="/success/:code" />
//     </Router>
//   )
// }

// export default PageRouter


// const getVocher = gql`
//   query getRedeemCode($redeemId: ID!) {
//     redeemCode(id: $redeemId, idType: SLUG) {
//       title
//       redeemAt {
//         redeemDate
//         size
//       }
//       redeemCategories {
//         nodes {
//           slug
//           categoryInfo {
//             image {
//               mediaItemUrl
//             }
//             size {
//               xl {
//                 stock
//                 outStock
//               }
//               small {
//                 stock
//                 outStock
//               }
//               medium {
//                 stock
//                 outStock
//               }
//               large {
//                 stock
//                 outStock
//               }
//             }
//             oneSize
//           }
//           name
//         }
//       }
//     }
//   }
// `

// // export default TselPage

// // import React from "react"
// // import { Link } from "gatsby"
// // import { Router } from "@reach/router"
// // // import gsap from "gsap"
// // import SEO from "../../components/seo"
// // import SubmitForm from "../../templates/redeem-form"
// // import SuccessPage from "../../templates/success"
// // import FailedPage from "../../templates/failed"
// // import { Container, Section } from "../../components/anti/grid/grid"
// // import { ApolloProvider } from '@apollo/client'
// // import { client } from '../../apollo/client'

// // // Images
// // import imgSample11a from "../../assets/img/sample/img_sample_1-1-a.jpg"
// // import HomePageComponents from '../../components/pages/home'
// // import queryString from "query-string"
// // import { useParams } from "@reach/router"

// // const TselPage = ({ location }) => {

// // const params = useParams();

// //   return (
// //     <>
// //       <SEO title="Redeem" />
// //       <ApolloProvider client={client}>
// //         {/* <h1>HALLO</h1> */}
// //         <HomePageComponents location={location} search={params.code} />
// //       </ApolloProvider>
// //     </>
// //   )
// // }




// // import React from "react"

// // import SEO from "../../components/seo"
// // import { ApolloProvider, gql, useQuery } from '@apollo/client'
// // import { client } from '../../apollo/client'
// // import { Router, useParams } from "@reach/router"

// // import HomePageComponents from '../../components/pages/home'
// // import { navigate } from "gatsby"

// // import Failed from './failed'
// // import Success from '../../templates/success'


// // const SubmitPage = ({ location }) => {

// // const params = useParams();

//   // const { loading, data } = useQuery(getVocher, {
//   //   variables: { redeemId: "tesi23d23fxn" },
//   // })

//   // React.useLayoutEffect(() => {

//   //   let monstCode = params?.code
//   // if (monstCode) {
//   //   if (data) {
//   //     console.log(data)
//   //     const redeemId = params.code
//   //     let availableSizes = []
//   //     let giftRedeem
//   //     const checkID = data && data.redeemCode
//   //     const checkAvailable =
//   //       checkID && checkID.redeemCategories.nodes[0].categoryInfo
//   //     const obj = checkAvailable && checkAvailable.size
//   //     if (checkID && checkID != null) {
//   //       giftRedeem = `Kamu akan me-redeem <strong>${checkID &&
//   //         checkID.redeemCategories.nodes[0].name}</strong>`
//   //       if (checkID && checkID.redeemAt.redeemDate === null) {
//   //         if (checkAvailable && checkAvailable.oneSize != null) {
//   //           availableSizes.push({
//   //             label: "One Size",
//   //             value: "one size",
//   //           })
//   //         } else if (checkAvailable && checkAvailable.oneSize === null) {
//   //           const sizeArr = obj && Object.keys(obj).map(key => [key, obj[key]])
//   //           sizeArr &&
//   //             sizeArr.map(item => {
//   //               if (item[1].stock && item[1].stock > 0 && !item[1].outStock) {
//   //                 availableSizes.push({
//   //                   label: item[0].toUpperCase(),
//   //                   value: item[0],
//   //                 })
//   //               }
//   //             })
//   //         }
//   //       } else if (checkID && checkID.redeemAt.redeemDate != null) {
//   //         giftRedeem = `Kode redeem sudah terpakai`
//   //         // navigate(`/tsel/failed/${redeemId}`)
//   //       }
//   //     } else if (checkID === null) {
//   //       giftRedeem = `Kode redeem tidak valid`
//   //       availableSizes.push({
//   //         label: "Tidak Tersedia",
//   //         value: "tidak tersedia",
//   //       })
//   //       // navigate(`/tsel/failed/${redeemId}`)
//   //     } else {
//   //       navigate(`/tsel/failed`)
//   //     }
//   //   }
//   // }
//   // if (data) {
//   //   const checkID = data && data.redeemCode
//   //   if (checkID) {
//   //     const checkAvailable = checkID && checkID.redeemCategories.nodes[0].categoryInfo
//   //     if (checkAvailable) {
//   //       const checkAvailable =
//   //         checkID && checkID.redeemCategories.nodes[0].categoryInfo
//   //       const obj = checkAvailable && checkAvailable.size
//   //       if (obj) {}
//   //     } else {
//   //       navigate(`/tsel/failed/${params.code}`)
//   //     }
//   //   } else {
//   //     navigate(`/tsel/failed/${params.code}`)
//   //   }
//   //   const checkAvailable =
//   //     checkID && checkID.redeemCategories.nodes[0].categoryInfo
//   //   const obj = checkAvailable && checkAvailable.size
//   // }
//   // }, [data])

// //   return (
// //     <>
// //       <SEO title="Home" />
// //       <ApolloProvider client={client}>
// //         <HomePageComponents search={params.code} />
// //       </ApolloProvider>
// //     </>
// //   )
// // }

// // const PageRouter = () => {
// //   return (
// //     <Router>
// //       {/* <SubmitPage path="/" /> */}
// //       <SubmitPage path="/tsel/:code" />
// //       <Success path="/tsel/success/:code" />
// //       <Failed path="/tsel/failed/:code" />
// //     </Router>
// //   )
// // }

// // export default PageRouter

// // const getVocher = gql`
// //   query getRedeemCode($redeemId: ID!) {
// //     redeemCode(id: $redeemId, idType: SLUG) {
// //       title
// //       redeemAt {
// //         redeemDate
// //         size
// //       }
// //       redeemCategories {
// //         nodes {
// //           slug
// //           categoryInfo {
// //             image {
// //               mediaItemUrl
// //             }
// //             size {
// //               xl {
// //                 stock
// //                 outStock
// //               }
// //               small {
// //                 stock
// //                 outStock
// //               }
// //               medium {
// //                 stock
// //                 outStock
// //               }
// //               large {
// //                 stock
// //                 outStock
// //               }
// //             }
// //             oneSize
// //           }
// //           name
// //         }
// //       }
// //     }
// //   }
// // `


// // import React from "react"
// // import { Router } from "@reach/router"

// // import Layout from "../../components/layout"
// // import SEO from "../../components/seo"

// // import SubmitForm from "../../templates/redeem-form"
// // import SuccessPage from "../../templates/success"
// // import FailedPage from "../../templates/failed"

// // import { Button } from "../../components/anti/buttons/buttons"
// // import { Container, Section } from "../../components/anti/grid/grid"

// // const RedeemTselPage = () => {
// //   return (
// //     <>
// //       <Layout>
// //         <SEO title="Home" />
// //         <Section>
// //           <Container className="mw-sm">
// //             <h1>Redeem</h1>
// //             <form>
// //               Form here
// //               <Button variant="primary" className="mt-4">
// //                 Redeem
// //               </Button>
// //             </form>
// //           </Container>
// //         </Section>
// //       </Layout>
// //     </>
// //   )
// // }

// // const PageRouter = () => {
// //   return (
// //     <Router basepath="/tsel">
// //       <RedeemTselPage path="/" />
// //       <SubmitForm path="/:code" />
// //       <SuccessPage path="/success/:code" />
// //       <FailedPage path="/failed/:code" />
// //     </Router>
// //   )
// // }

// // export default PageRouter










// // import React from "react"
// // import { Link } from "gatsby"
// // import { Router } from "@reach/router"
// // // import gsap from "gsap"
// // import SEO from "../components/seo"
// // import SubmitForm from "../templates/redeem-form"
// // import SuccessPage from "../templates/success"
// // import FailedPage from "../templates/failed"
// // import { Container, Section } from "../components/anti/grid/grid"
// // import { ApolloProvider } from '@apollo/client'
// // import { client } from '../apollo/client'

// // // Images
// // import imgSample11a from "../assets/img/sample/img_sample_1-1-a.jpg"
// // import HomePageComponents from '../components/pages/home'
// // import queryString from "query-string"

// // const TselPage = ({ location }) => {
// //   return (
// //     <>
// //       <SEO title="Home" />
// //       <ApolloProvider client={client}>
// //         <HomePageComponents />
// //       </ApolloProvider>
// //     </>
// //   )
// // }

// // // const PageRouter = () => {
// // //   return (
// // //     <Router>
// // //       <HomePage path="/" />
// // //       <SubmitForm path="/:code" />
// // //       <SuccessPage path="/success/:code" />
// // //       <FailedPage path="/failed/:code" />
// // //     </Router>
// // //   )
// // // }

// // export default TselPage


